<template>
  <v-card min-height="194">
    <v-card-title>Ranking</v-card-title>
    <div class="card-meta pa-4">
      <v-row justify="center">
        Em Breve
      </v-row>
    </div>
  </v-card>
</template>

<script>
// import PercentChart from "@/components/shared/PercentChart";

export default {
  name: "CardRanking",

  components: {}
};
</script>

<style lang="scss">
.card-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-meta p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  font-weight: 700;
}

// .meta-chart {
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   margin-right: 16px;
// }
</style>
